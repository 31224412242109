// The list is based on https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
// And formatted / prepared by ChatGPT.

// Keys
export const key_0 = '0';
export const key_1 = '1';
export const key_3 = '3';
export const key_4 = '4';
export const key_5 = '5';
export const key_6 = '6';
export const key_7 = '7';
export const key_8 = '8';
export const key_9 = '9';
export const key_A = 'A';
export const key_B = 'B';
export const key_C = 'C';
export const key_D = 'D';
export const key_E = 'E';
export const key_F = 'F';
export const key_G = 'G';
export const key_H = 'H';
export const key_I = 'I';
export const key_J = 'J';
export const key_K = 'K';
export const key_L = 'L';
export const key_M = 'M';
export const key_N = 'N';
export const key_O = 'O';
export const key_P = 'P';
export const key_Q = 'Q';
export const key_R = 'R';
export const key_S = 'S';
export const key_T = 'T';
export const key_U = 'U';
export const key_V = 'V';
export const key_W = 'W';
export const key_X = 'X';
export const key_Y = 'Y';
export const key_Z = 'Z';
export const key_Accept = 'Accept';
export const key_Add = 'Add';
export const key_Alt = 'Alt';
export const key_ArrowDown = 'ArrowDown';
export const key_ArrowLeft = 'ArrowLeft';
export const key_ArrowRight = 'ArrowRight';
export const key_ArrowUp = 'ArrowUp';
export const key_Attn = 'Attn';
export const key_Backspace = 'Backspace';
export const key_BrowserBack = 'BrowserBack';
export const key_BrowserFavorites = 'BrowserFavorites';
export const key_BrowserForward = 'BrowserForward';
export const key_BrowserHome = 'BrowserHome';
export const key_BrowserRefresh = 'BrowserRefresh';
export const key_BrowserSearch = 'BrowserSearch';
export const key_BrowserStop = 'BrowserStop';
export const key_CapsLock = 'CapsLock';
export const key_Clear = 'Clear';
export const key_Code = 'Code';
export const key_Comma = 'Comma';
export const key_ContextMenu = 'ContextMenu';
export const key_Control = 'Control';
export const key_Convert = 'Convert';
export const key_Copy = 'Copy';
export const key_CrSel = 'CrSel';
export const key_Cut = 'Cut';
export const key_Dead = 'Dead';
export const key_Decimal = 'Decimal';
export const key_Delete = 'Delete';
export const key_Divide = 'Divide';
export const key_Down = 'Down';
export const key_Eisu = 'Eisu';
export const key_End = 'End';
export const key_Enter = 'Enter';
export const key_EraseEof = 'EraseEof';
export const key_Escape = 'Escape';
export const key_Execute = 'Execute';
export const key_Exsel = 'Exsel';
export const key_F1 = 'F1';
export const key_F10 = 'F10';
export const key_F11 = 'F11';
export const key_F12 = 'F12';
export const key_F13 = 'F13';
export const key_F14 = 'F14';
export const key_F15 = 'F15';
export const key_F16 = 'F16';
export const key_F17 = 'F17';
export const key_F18 = 'F18';
export const key_F19 = 'F19';
export const key_F2 = 'F2';
export const key_F20 = 'F20';
export const key_F21 = 'F21';
export const key_F22 = 'F22';
export const key_F23 = 'F23';
export const key_F24 = 'F24';
export const key_F3 = 'F3';
export const key_F4 = 'F4';
export const key_F5 = 'F5';
export const key_F6 = 'F6';
export const key_F7 = 'F7';
export const key_F8 = 'F8';
export const key_F9 = 'F9';
export const key_Final = 'Final';
export const key_Gana = 'Gana';
export const key_GetKeyState = 'GetKeyState';
export const key_Help = 'Help';
export const key_Home = 'Home';
export const key_Hyper = 'Hyper';
export const key_Insert = 'Insert';
export const key_IntlBackslash = 'IntlBackslash';
export const key_KeyA = 'KeyA';
export const key_KeyB = 'KeyB';
export const key_KeyC = 'KeyC';
export const key_KeyD = 'KeyD';
export const key_KeyE = 'KeyE';
export const key_KeyF = 'KeyF';
export const key_KeyG = 'KeyG';
export const key_KeyH = 'KeyH';
export const key_KeyI = 'KeyI';
export const key_KeyJ = 'KeyJ';
export const key_KeyK = 'KeyK';
export const key_KeyL = 'KeyL';
export const key_KeyM = 'KeyM';
export const key_KeyN = 'KeyN';
export const key_KeyO = 'KeyO';
export const key_KeyP = 'KeyP';
export const key_KeyQ = 'KeyQ';
export const key_KeyR = 'KeyR';
export const key_KeyS = 'KeyS';
export const key_KeyT = 'KeyT';
export const key_KeyU = 'KeyU';
export const key_KeyV = 'KeyV';
export const key_KeyW = 'KeyW';
export const key_KeyX = 'KeyX';
export const key_KeyY = 'KeyY';
export const key_KeyZ = 'KeyZ';
export const key_LAlt = 'LAlt';
export const key_LAmpersand = 'LAmpersand';
export const key_LControl = 'LControl';
export const key_LShift = 'LShift';
export const key_LWin = 'LWin';
export const key_LaunchApp1 = 'LaunchApp1';
export const key_LaunchApp2 = 'LaunchApp2';
export const key_LaunchMail = 'LaunchMail';
export const key_LaunchMediaPlayer = 'LaunchMediaPlayer';
export const key_Left = 'Left';
export const key_LeftBracket = 'LeftBracket';
export const key_LogOff = 'LogOff';
export const key_MailForward = 'MailForward';
export const key_MailReply = 'MailReply';
export const key_MailSend = 'MailSend';
export const key_MediaNextTrack = 'MediaNextTrack';
export const key_MediaPlayPause = 'MediaPlayPause';
export const key_MediaPrevTrack = 'MediaPrevTrack';
export const key_MediaStop = 'MediaStop';
export const key_MediaTrackNext = 'MediaTrackNext';
export const key_MediaTrackPrevious = 'MediaTrackPrevious';
export const key_Meta = 'Meta';
export const key_Minus = 'Minus';
export const key_ModeChange = 'ModeChange';
export const key_Multiply = 'Multiply';
export const key_Numpad0 = 'Numpad0';
export const key_Numpad1 = 'Numpad1';
export const key_Numpad2 = 'Numpad2';
export const key_Numpad3 = 'Numpad3';
export const key_Numpad4 = 'Numpad4';
export const key_Numpad5 = 'Numpad5';
export const key_Numpad6 = 'Numpad6';
export const key_Numpad7 = 'Numpad7';
export const key_Numpad8 = 'Numpad8';
export const key_Numpad9 = 'Numpad9';
export const key_NumLock = 'NumLock';
export const key_Open = 'Open';
export const key_OSLeft = 'OSLeft';
export const key_OSRight = 'OSRight';
export const key_PageDown = 'PageDown';
export const key_PageUp = 'PageUp';
export const key_Paste = 'Paste';
export const key_Pause = 'Pause';
export const key_Period = 'Period';
export const key_Power = 'Power';
export const key_Print = 'Print';
export const key_PrintScreen = 'PrintScreen';
export const key_Process = 'Process';
export const key_Props = 'Props';
export const key_Quote = 'Quote';
export const key_Right = 'Right';
export const key_RightBracket = 'RightBracket';
export const key_ScrollLock = 'ScrollLock';
export const key_Select = 'Select';
export const key_Semicolon = 'Semicolon';
export const key_Shift = 'Shift';
export const key_Slash = 'Slash';
export const key_Sleep = 'Sleep';
export const key_Space = 'Space';
export const key_Stop = 'Stop';
export const key_Subtract = 'Subtract';
export const key_Tab = 'Tab';
export const key_Undo = 'Undo';
export const key_Up = 'Up';
export const key_VolumeDown = 'VolumeDown';
export const key_VolumeMute = 'VolumeMute';
export const key_VolumeUp = 'VolumeUp';
export const key_Win = 'Win';
export const key_Zoom = 'Zoom';